export default [
  {
    title: 'Administración',
    route: 'home',
    icon: 'SlidersIcon',
    action: 'read',
    resource: 'menu.administration',
    children: [
      {
        title: 'Roles y Permisos',
        route: 'admin-roles',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'roles.view',
      },
      {
        title: 'Clientes',
        route: 'admin-customers',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'customers.view',
      },
      {
        title: 'Usuarios',
        route: 'admin-users',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'users.view',
      }
    ],
  },
  {
    title: 'Stock',
    route: 'stock',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'menu.stock',
    children: [
      {
        title: 'Gestionar inventario',
        route: 'stock-manage',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'stock.view',
      },
      {
        title: 'Masivo',
        route: 'stock-masive',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'stock_masive.view',
      },
    ],
  },
  {
    title: 'Reportes',
    route: 'reports',
    icon: 'PrinterIcon',
    action: 'read',
    resource: 'menu.reports',
    children: [
      {
        title: 'Inventario',
        route: 'report-stock',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'report.stock',
      },
      {
        title: 'Notificaciones solicitadas',
        route: 'report-requested-notification',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'report.requested-notification',
      },
      {
        title: 'Notificaciones enviadas',
        route: 'report-sent-notification',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'report.sent-notification',
      },
      {
        title: 'Consulta Medicamentos',
        route: 'report-look-medicines',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'report.look_medicines',
      },
    ],
  },
  {
    title: 'Medicamentos',
    route: 'customers.search',
    icon: 'SearchIcon',
    action: 'read',
    resource: 'customers.search'
  },
  {
    title: 'Configuracion',
    route: 'parametitation',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'menu.reports',
    children: [
      {
        title: 'Parametrizacion',
        route: 'admin-parametritation',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'users.view',
      }
    ],
  },
  {
    title: 'Registro de Separados',
    route: 'register-separate',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'menu.reports',
    children: [
      {
        title: 'Registro de Separados',
        route: 'admin-register-separate',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'users.view',
      }
    ],
  },
  {
    title: 'Entregas',
    route: 'admin-deliveries',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'menu.reports',
    children: [
      {
        title: 'Historico',
        route: 'admin-deliveries',
        icon: 'CircleIcon',
        action: 'read',
        resource: 'users.view',
      }
    ],
  },  
]
